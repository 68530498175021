import React, {useState, useEffect} from "react"
import {Link, useLocation, useSearchParams} from "react-router-dom";
// MUI
import {
    Box,
    Divider,
    Grid,
    Button,
    Typography,
    Collapse,
    TextField, Stack, Tooltip, IconButton, InputLabel, Select, MenuItem, FormControl, Alert
} from "@mui/material";
import {Add, FilterAltOff} from "@mui/icons-material";
// Data
import {useData} from "@/context/PackagingDataProvider";
// Components
import {OrganisationFilterSelect} from "@/components/UI";
import {ProductsDataTable} from "@/components/Products/index";
import BrandRangeSelect from "@/components/UI/BrandRangeSelect/BrandRangeSelect";
// Interfaces
import {DataTablePageEvent} from "primereact/datatable";
import {useUser} from "@/context/UserProvider";
import {useTranslation} from "react-i18next";
import {ChevronUpIcon} from "primereact/icons/chevronup";
import {ChevronDownIcon} from "primereact/icons/chevrondown";
import {useModule} from "@/context/ModuleProvider";
import {usePermissions} from "@/context/PermissionsProvider";

export default function ProductsTab() {

    const {t} = useTranslation(["products", "common"])
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()
    const {seeAnalyticsForProducts} = useUser()
    const {isAccountRole} = usePermissions()
    const {
        brands, getBrands,
        ranges, getRanges,
        products, getProducts,
        commonFilters, setCommonFilters, clearCommonFilters
    } = useData()
    const {modules} = useModule()

    // Selection
    const [selection, setSelection] = useState<any>(null)
    const [multipleSelection, setMultipleSelection] = useState<any>(null)
    // Specific depth filter for ranges
    const [affectedRanges, setAffectedRanges] = useState<any>(null)
    // Other filters
    const [showFilters, setShowFilters] = useState<boolean>(true)
    const [filters, setFilters] = useState<any>({ brand: "", range: "", search: "", type: "", archived: ""})
    const [activeFilters, setActiveFilters] = useState<boolean>(false)
    // Pagination
    const [page, setPage] = useState<any>({first: 0, max_rows: 10, currentPage: 1,});

    // Initiator
    useEffect(() => {
        if (!brands) getBrands()
        if (!ranges) getRanges()

        let query = new URLSearchParams(location.search)
        let HCname = query.get("HCname")
        let HCrange = query.get("HCrange")

        if (!!HCrange && !!HCname) {
            clearCommonFilters()
            setFilters({
                ...filters,
                search: HCname,
                range: HCrange
            })
            setActiveFilters(true)
            searchParams.delete('HCname')
            searchParams.delete("HCrange")
            searchParams.delete("tab")
            setSearchParams(searchParams)
        } else if (!!commonFilters.organisation) {
            let _filters = { org: commonFilters.organisation }
            getProducts(_filters)
            getProducts({..._filters, page: 1, max_rows: page.max_rows})
            setPage({...page, first: 0, currentPage: 1})
            setActiveFilters(true)
        } else getProducts()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // on filter
    useEffect(() => {
        if (!!filters.brand || !!filters.range || !!filters.search || !!filters.type || !!filters.archived) {

            let _filters = filters

            if (!!commonFilters.organisation) _filters = {...filters, org: commonFilters.organisation}
            getProducts({..._filters, page: 1, max_rows: page.max_rows})
            setPage({...page, first: 0, currentPage: 1})
            setActiveFilters(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, commonFilters])

    useEffect(() => {
        if (!!ranges && affectedRanges === null) setAffectedRanges(ranges)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ranges])

    useEffect(() => {
        if (filters.brand !== "") {
            let _rangesInBrand = ranges.filter((range: any) => range.brand.id === filters.brand)
            setAffectedRanges(_rangesInBrand)
        } else setAffectedRanges(ranges)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters.brand])

    // Pagination
    const onPageChange = (e: DataTablePageEvent) => {
        if (e.page !== undefined) {
            setPage({
                max_rows: e.rows,
                first: e.first,
                currentPage: e.page +1
            });
            getProducts({ ...filters, page: e.page +1, max_rows: e.rows })
        }
    }

    // Analytics
    const seeInAnalytics = (rowData?: any) => {
        if (!!multipleSelection && multipleSelection.length > 0) {
            const payload: any = ["analytics-uuids"]

            multipleSelection.forEach((product: any) => {
                payload.push({ label: product.name, uuid: product.id })
            })

            seeAnalyticsForProducts(payload)
        } else if (!!rowData) {
            const payload = ["analytics-uuids", { label: rowData.name, uuid: rowData.id }]
            seeAnalyticsForProducts(payload)
        } else return
    }

    const renderFiltersSection = () => {

        const clearFilters = () => {
            setFilters({
                brand: "",
                range: "",
                search: "",
                type: "",
                archived: ""
            })
            clearCommonFilters()
            setPage({...page, first: 0, currentPage: 1})
            getProducts()
            setActiveFilters(false)
        }

        return (
            <>
                <Grid item xs={12}>
                    <Stack direction={"row"} spacing={2} sx={{ mb: 1 }} alignContent={"center"}>
                        <Typography variant={"body1"} fontWeight={600}>
                            {t("filters")}
                        </Typography>
                        <Tooltip
                            title={showFilters ? t("common:hide_filters") : t("common:show_filters")}
                            placement={"right"}
                            arrow
                        >
                            <IconButton size={"small"} onClick={() => setShowFilters(!showFilters)}>
                                {showFilters ? <ChevronUpIcon /> : <ChevronDownIcon />}
                            </IconButton>
                        </Tooltip>
                    </Stack>
                    <Collapse in={showFilters}>
                        <Grid container justifyContent={{ xs: "center", md: "space-between" }} rowSpacing={1} columnSpacing={2} columns={12}>
                            <Grid item xs={12}>
                                <Grid container justifyContent={"flex-start"} spacing={2} columns={20}>
                                    <Grid item xs={20} md={10} lg={4}>
                                        <OrganisationFilterSelect
                                            small
                                            organisationFilter={commonFilters.organisation}
                                            setOrganisationFilter={(el: any) => setCommonFilters({...commonFilters, organisation: el})}
                                            {...{t}}
                                        />
                                    </Grid>
                                    <Grid item xs={20} md={10} lg={4}>
                                        <BrandRangeSelect
                                            small
                                            label={t("brand")}
                                            type={"brand"}
                                            data={brands}
                                            value={filters.brand}
                                            setValue={(e: any) => setFilters({...filters, brand: e.target.value})}
                                            {...{t}}
                                        />
                                    </Grid>
                                    <Grid item xs={20} md={10} lg={4}>
                                        <BrandRangeSelect
                                            small
                                            label={t("range")}
                                            type={"range"}
                                            data={affectedRanges}
                                            value={filters.range}
                                            setValue={(e: any) => setFilters({...filters, range: e.target.value})}
                                            {...{t}}
                                        />
                                    </Grid>
                                    <Grid item xs={20} md={10} lg={4}>
                                        <TextField
                                            size={"small"}
                                            type={"text"}
                                            label={t("name_or_short_url")}
                                            fullWidth
                                            value={filters.search}
                                            onChange={(e) => setFilters({...filters, search: e.target.value})}
                                        />
                                    </Grid>
                                    <Grid item xs={20} md={10} lg={4} sx={{ display: (!!modules && modules.gs1 && modules.geoloc) ? "flex" : "none" }}>
                                        <FormControl fullWidth size={"small"}>
                                            <InputLabel id={`type-selector`}>
                                                {t("type")}
                                            </InputLabel>
                                            <Select
                                                label={t("type")}
                                                labelId={`type-selector`}
                                                id={`packaging-type-selector`}
                                                value={filters.type || ""}
                                                onChange={(e: any) => setFilters({...filters, type: e.target.value})}
                                            >
                                                <MenuItem value={""} disabled>{t("no_filter")}</MenuItem>
                                                <MenuItem value={"Standard"}>{t("geolocation")}</MenuItem>
                                                <MenuItem value={"GS1"}>{t("gs1")}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={20} md={10} lg={4}>
                                        <FormControl fullWidth size={"small"}>
                                            <InputLabel id={`status-selector`}>
                                                {t("status")}
                                            </InputLabel>
                                            <Select
                                                label={t("status")}
                                                labelId={`status-selector`}
                                                id={`packaging-status-selector`}
                                                value={filters.archived || ""}
                                                onChange={(e: any) => setFilters({...filters, archived: e.target.value})}
                                            >
                                                <MenuItem value={""} disabled>{t("all")}</MenuItem>
                                                <MenuItem value={"false"}>{t("active")}</MenuItem>
                                                <MenuItem value={"true"}>{t("archived")}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Collapse in={activeFilters}>
                                    <Alert
                                        severity={"info"}
                                        sx={{ pb: 1 }}
                                        action={
                                            <Button
                                                onClick={() => clearFilters()}
                                                size={"small"} disableElevation
                                                variant={"contained"}
                                                color={"secondary"}
                                                startIcon={<FilterAltOff />}
                                            >
                                                {t("clear_filters")}
                                            </Button>
                                        }
                                    >
                                        {t("filters_are_active")}
                                    </Alert>
                                </Collapse>
                            </Grid>
                        </Grid>
                    </Collapse>
                </Grid>
            </>
        )
    }

    return (
        <>
            <Box sx={{ p: 2 }}>
                <Grid container justifyContent={{ xs: "center" }} rowSpacing={3} columnSpacing={1}>
                    <Grid item xs={12}>
                        <Typography variant={"h4"} component={"p"}>
                            {t("products_list")}
                        </Typography>
                    </Grid>
                    <Grid item xs={"auto"} md={12}>
                        <Stack direction={"row"} spacing={2}>
                            <Button
                                variant={"contained"}
                                disableElevation
                                startIcon={<Add />}
                                component={Link}
                                to={"/products/create"}
                                disabled={!modules || !modules.geoloc || isAccountRole(["analyst"])}
                            >
                                {t("product_create")}
                            </Button>
                            <Button
                                variant={"contained"}
                                disableElevation
                                startIcon={<Add />}
                                component={Link}
                                to={"/products/gs1/create"}
                                disabled={!modules || !modules.gs1 || isAccountRole(["analyst"])}
                            >
                                {t("product_gs1_create")}
                            </Button>
                            <Button
                                color={"secondary"}
                                variant={"contained"}
                                disableElevation
                                disabled={!multipleSelection || multipleSelection.length < 1}
                                onClick={() => seeInAnalytics()}
                            >
                                {t("product_see_in_analytics")}
                            </Button>
                        </Stack>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    {renderFiltersSection()}

                    <Grid item xs={12}>
                        <ProductsDataTable
                            disableActions={isAccountRole(["analyst"])}
                            {...{
                                products,
                                page, onPageChange,
                                selection, setSelection,
                                multipleSelection, setMultipleSelection,
                                seeInAnalytics,
                                t
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}