import React, {useEffect, useState} from "react"
import {useLocation, useNavigate} from "react-router-dom"
// MUI
import {Button, Container, Grid, Paper} from "@mui/material";
import {ArrowLeft} from "@mui/icons-material";
// Components
import {DismountConfirmationDialog} from "@/components/UI";
import {ProductsEditor} from "@/components/Products";
import {AppHeader} from "@/components/DashboardComponents";
// Translation
import {useTranslation} from "react-i18next";
// Data
import {useData} from "@/context/PackagingDataProvider";

export default function ProductBuilder(props: any) {

    const {state} = useLocation();
    const redirect = useNavigate()

    const {creating, editing, gs1} = props
    const {createAny, editAny, brands, ranges, getBrands, getRanges, getSingleProduct} = useData()
    const {t} = useTranslation(["products", "common"])

    const savedProduct = localStorage.getItem("savedProduct")

    // Dismount confirmation
    const [openDismountDialog, setOpenDismountDialog] = useState<string>("")
    // Data
    const [product, setProduct] = useState<any>({
        brand: "",
        range: "",
        id: "",
        name: "",
        qrcode: "",
        bypass_app: !!gs1,
        sku: "",
        default_url: "",
        type: gs1 ? "GS1" : "Standard"
    })

    const [GS1Product, setGS1Product] = useState<any>({
        primaryKey: "01",
        primaryKeyValue: "",
        key_identifiers: [
            { key: "22", value: "" },
            { key: "10", value: "" },
            { key: "21", value: "" },
        ],
        dataAttributes: [
            { key: "", value: "" }
        ]
    })

    // Disabling navigation
    const blocking = Boolean(!editing && !!product.qrcode)

    useEffect(() => {
        // Fetch brands if not already done
        if (!brands) getBrands()
        if (!ranges) getRanges()

        if (editing) {
            getSingleProduct(state.id).then((res: any) => {
                setProduct({
                    range: res.range.id,
                    id: res.id,
                    name: res.name,
                    qrcode: res.qr_code.id,
                    bypass_app: res.bypass_app,
                    sku: res.sku,
                    default_url: res.default_url,
                    type: gs1 ? "GS1" : "Standard"
                })
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, state, savedProduct])

    // GS1 - clearing key qualifiers on primary key change
    useEffect(() => {
        setGS1Product({...GS1Product, key_identifiers: []})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [GS1Product.primaryKey])

    const handleCreateProduct = () => {
        createAny(product, "product").then((created: any) => {
            if (created) redirect("/?tab=products")
        })
    }

    const handleEditProduct = () => {
        editAny(product, "product").then((updated: any) => {
            if (updated) redirect("/?tab=products")
        })
    }

    const handleQuit = () => {
        if (blocking) {
            setOpenDismountDialog("/?tab=products")
        } else redirect("/?tab=products")
    }

    const onNavigateOut = () => {

        let href = openDismountDialog
        setOpenDismountDialog("")

        if (String(href).startsWith("http")) {
            let a = document.createElement('a')
            a.href = href
            a.target = "_parent"
            a.click()
        } else redirect(href)
    }


    return (
        <>
            <Container maxWidth={"md"} sx={{ pt: "32px" }}>
                <AppHeader
                    paths={[{name: "common:packaging", path: "/"}]}
                    positionName={editing ? "product_editor" : "product_creation"}
                    title={editing ? "product_editor" : "product_creation"}
                    subTitle={editing ? product.name : ""}
                    blocking={blocking}
                    onBlock={(href: string) => setOpenDismountDialog(href)}
                />

                <Grid container justifyContent={"center"} rowSpacing={3}>
                    <Grid item xs={12}>
                        <Button
                            startIcon={<ArrowLeft />}
                            onClick={() => handleQuit()}
                            color={"secondary"}
                            variant={"outlined"}
                        >
                            {t("common:back")}
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper variant={"outlined"} sx={{ p: 2, borderRadius: 5 }}>
                            <ProductsEditor
                                onCreate={() => handleCreateProduct()}
                                onEdit={() => handleEditProduct()}
                                {...{
                                    creating, editing,
                                    GS1Product, setGS1Product,
                                    product, setProduct,
                                    brands, ranges,
                                    gs1,
                                    t
                                }}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>

            <DismountConfirmationDialog
                onDismount={() => {
                    onNavigateOut()
                }}
                {...{openDismountDialog, setOpenDismountDialog, t}}
            />
        </>
    )
}