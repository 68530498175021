import React, {useRef, useState} from "react"
import {useNavigate} from "react-router-dom"
// Primereact
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
// MUI
import {Grid, Tooltip, Typography, IconButton, Skeleton, Paper} from "@mui/material";
import {Edit, Delete} from "@mui/icons-material";
// Components
import {DeleteAnyDialog} from "@/components/UI";
// Data
import {useData} from "@/context/PackagingDataProvider";
// Utils
import {formatJSIso8601ToHumanString} from "@/utils/timeFunctions";
import {ContextMenu} from "primereact/contextmenu";

export default function RangesDataTable(props: any) {

    const {ranges, rangesLogos, disableActions, t} = props
    const {deleteAny, getRanges} = useData()
    const navigate = useNavigate()

    const contextMenu = useRef<ContextMenu>(null)

    const [selection, setSelection] = useState<any>(null)
    const [deletion, setDeletion] = useState<any>(null)
    // Delete dialog
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)

    const contextMenuModel = [
        { label: t("common:edit"), icon: <Edit fontSize={"small"} sx={{ mr: 1 }} />, command: () => navigate("/ranges/edit", { state: { ...selection } }), disabled: disableActions },
        { separator: true },
        { label: t("common:delete"), icon: <Delete fontSize={"small"} sx={{ mr: 1 }} />, command: () => handleDelete(selection), disabled: disableActions}
    ]

    // Handlers
    const handleDelete = (brand: any) => {
        setDeletion(brand)
        setOpenDeleteDialog(true)
    }

    // CRUD
    const deleteRange = () => {
        deleteAny(deletion, "range").then((deleted: any) => { if (!!deleted) getRanges() })
        setOpenDeleteDialog(false)
        setDeletion(null)
    }

    // DataTable
    const dateBodyTemplate = (rowData: any) => {
        return <Typography>{formatJSIso8601ToHumanString(rowData.created_at)}</Typography>
    }

    const actionBodyTemplate = (rowData: any) => {

        const handleEdit = () => {
            navigate("/ranges/edit", { state: { ...rowData } })
        }

        return (
            <Grid container style={{minWidth: '100px'}}>
                <Grid item>
                    <Tooltip placement={"top"} arrow title={`${t("edit")}`}>
                        <IconButton
                            aria-label="edit"
                            onClick={handleEdit}
                            disabled={disableActions}
                        ><Edit /></IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip placement={"top"} arrow title={`${t("delete")}`}>
                        <IconButton
                            aria-label="delete"
                            value={rowData.uuid}
                            onClick={() => handleDelete(rowData)}
                            disabled={disableActions}
                        ><Delete/></IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        );
    }

    const logoTemplate = (rowData: any) => {

        if (rowData.logo_id === "" || !rowData.logo_id) return <></>

        if (!!ranges) {
            if (
                !rangesLogos ||
                rangesLogos[rowData.id] === undefined ||
                rangesLogos[rowData.id] === null
            ) return <Skeleton variant="circular" width={50} height={50}/>

            return <img width={'50px'} height={'auto'} src={rangesLogos[rowData.id] as any as string} alt="range-logo"/>
        }
        return <></>
    }

    return (
        <>
            <>
                <Paper variant={"outlined"} sx={{ borderRadius: 5, overflow: "hidden" }}>
                    <ContextMenu
                        model={contextMenuModel}
                        ref={contextMenu}
                        onHide={() => setSelection(null)}
                        breakpoint={"600px"}
                    />
                    <DataTable
                        value={ranges}
                        rowHover
                        emptyMessage={t("no_elements_found")}
                        onContextMenu={(e) => contextMenu.current?.show(e.originalEvent)}
                        contextMenuSelection={selection}
                        onContextMenuSelectionChange={(e) => setSelection(e.value)}
                    >
                        <Column header={t("logo")} bodyStyle={{textAlign: 'center'}} body={logoTemplate}/>
                        <Column field="name" header={t("label")} sortable/>
                        <Column field="created_at" header={t("created_at")} body={dateBodyTemplate} sortable/>
                        <Column field="description" header={t("description")} />
                        <Column field="brand.name" header={t("brand")} sortable />
                        <Column header={t("actions")} body={actionBodyTemplate}/>
                    </DataTable>
                </Paper>

                <DeleteAnyDialog
                    open={openDeleteDialog}
                    onClose={() => setOpenDeleteDialog(false)}
                    onDelete={() => deleteRange()}

                    assetName={deletion?.name}
                    text={t("ranges_delete_alert")}
                    {...{t}}
                />
            </>
        </>
    )
}