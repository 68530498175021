import React, {useEffect, useRef, useState} from "react"
import {useNavigate} from "react-router-dom"
// Primereact
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {ContextMenu} from "primereact/contextmenu";
// MUI
import {Grid, Tooltip, IconButton, Paper, Chip} from "@mui/material";
import {Edit} from "@mui/icons-material";
// Data
import {useData} from "@/context/PackagingDataProvider";
// Utils
import {languageCodeToName} from "@/utils";
// UI
import {AssociatedBrandsDialog} from "@/components/UI";
// @ts-ignore
import {ReactComponent as BrandsLogo} from "@/assets/svg/brands.svg"

export default function AppsDataTable(props: any) {

    const {apps, disableActions, t} = props
    const {getApps} = useData()
    const navigate = useNavigate()

    const contextMenu = useRef<ContextMenu>(null)

    const [selection, setSelection] = useState<any>(null)
    // Brands menu
    const [openBrandsDialog, setOpenBrandsDialog] = useState<string | boolean>(false)

    useEffect(() => {
        if (!apps) getApps()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const contextMenuModel = [
        { label: t("common:edit"), icon: <Edit fontSize={"small"} sx={{ mr: 1 }} />, command: () => navigate("/apps/edit", { state: { ...selection } }), disabled: disableActions},
        { label: t("associated_brands"), icon: <BrandsLogo style={{ fill: "#646464", marginRight: 8, height: 20, width: 20 }} />, command: () => setOpenBrandsDialog(selection.id), disabled: disableActions},
    ]

    // Data table body
    const brandsBodyTemplate = (rowData: any) => {
        if (!rowData.brands || rowData.brands.length < 1) return <Chip label={`${t("no_brands_for_app")}`} />

        let translationsString: any = []

        for (let brand of rowData.brands) {
            translationsString.push(brand.name)
        }

        return (
            <div>
                <Tooltip title={translationsString.toString().replaceAll(",", ", ")} placement={"top"} arrow>
                    <Chip label={`${rowData.brands.length} ${t("brands")}`} color={"secondary"} />
                </Tooltip>
            </div>
        )
    }

    const defaultAppBodyTemplate = (rowData: any) => {
        if (rowData.default) return <Chip label={t("default")} color={"primary"} />
        return <Chip label={"-"} />
    }

    const translationsBodyTemplate = (rowData: any) => {
        if (!rowData.languages || rowData.languages.length < 1) return <></>

        let translationsString: any = []

        for (let language of rowData.languages) {
            let _lng = languageCodeToName(language.name)
            translationsString.push(_lng)
        }

        return (
            <div>
                <Tooltip title={translationsString.toString().replaceAll(",", ", ")} placement={"top"} arrow>
                    <Chip label={`${rowData.languages.length} ${t("translations")}`} color={"secondary"} />
                </Tooltip>
            </div>
        )
    }

    const actionBodyTemplate = (rowData: any) => {

        const handleEdit = () => {
            navigate("/apps/edit", { state: { ...rowData } })
        }

        return (
            <Grid container style={{minWidth: '100px'}}>
                <Grid item>
                    <Tooltip placement={"top"} arrow title={`${t("edit")}`}>
                        <IconButton
                            aria-label="edit"
                            onClick={handleEdit}
                            disabled={disableActions}
                        ><Edit /></IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip placement={"top"} arrow title={`${t("associated_brands")}`}>
                        <IconButton
                            aria-label="brands"
                            onClick={() => { setOpenBrandsDialog(rowData.id) }}
                            disabled={disableActions}
                        ><BrandsLogo style={{ fill: "#646464" }} /></IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        );
    }

    return (
        <>
            <>
                <Paper variant={"outlined"} sx={{ borderRadius: 5, overflow: "hidden" }}>
                    <ContextMenu
                        model={contextMenuModel}
                        ref={contextMenu}
                        onHide={() => setSelection(null)}
                        breakpoint={"600px"}
                    />
                    <DataTable
                        value={apps}
                        rowHover
                        emptyMessage={t("No_elements_found")}
                        onContextMenu={(e) => contextMenu.current?.show(e.originalEvent)}
                        contextMenuSelection={selection}
                        onContextMenuSelectionChange={(e) => setSelection(e.value)}
                    >
                        <Column header={t("brands")} body={brandsBodyTemplate} />
                        <Column header={t("common:default")} body={defaultAppBodyTemplate} />
                        <Column header={t("app_translations")} body={translationsBodyTemplate} />
                        <Column header={t("Actions")} body={actionBodyTemplate}/>
                    </DataTable>

                    <AssociatedBrandsDialog
                        appUuid={openBrandsDialog}
                        open={Boolean(openBrandsDialog)}
                        onClose={() => {
                            setOpenBrandsDialog(false)
                            setSelection(null)
                            getApps()
                        }}
                        {...{t}}
                    />
                </Paper>
            </>
        </>
    )
}