import React, {useState, useEffect} from "react"
// MUI
import {
    Button,
    Grid,
    TextField,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    Paper, Divider, Skeleton, Stack, FormLabel, FormControlLabel, Switch, Collapse
} from "@mui/material";
import {Add, QrCode2, Save} from "@mui/icons-material";
// Data
import {useData} from "@/context/PackagingDataProvider";
// Assets
// @ts-ignore
import {ReactComponent as BrandsLogo} from "@/assets/svg/brands.svg"
// @ts-ignore
import {ReactComponent as RangesLogo} from "@/assets/svg/ranges.svg"
// Misc
import {regexValidUrl} from "@/utils";
// Components
import {PaperLabelled, QRCodeGeneratorDialog} from "@/components/UI";
import ProductsGS1Form from "@/components/Products/gs1/ProductsGS1Form";
import Loading from "@/components/Loading/Loading";

export default function ProductsEditor(props: any) {

    const {
        creating, editing,
        GS1Product, setGS1Product,
        product, setProduct,
        brands, ranges,
        onCreate, onEdit,
        gs1,
        t
    } = props

    const {getSingleQRCode, verifyGTIN, getGS1QRCode} = useData()

    const styles = {
        editorGrid: { mb: 1 },
        logoImagePaper: { p: 2 },
        alignCenter: { textAlign: "center" },
        mainSection: { p: 2 },
        subSection: { backgroundColor: "#efefef", p: 2},
        qrcodePreviewTextWrapper: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center"
        },
        submitButton: { px: 10, maxWidth: "100%" }
    }

    const [selectedBrand, setSelectedBrand] = useState<any>("")
    const [rangesInBrand, setRangesInBrand] = useState<any>([])
    // QR Code
    const [qrcode, setQRCode] = useState<any>(null)
    const [qrcodePreview, setQRCodePreview] = useState<any>(null)
    const [openGenerator, setOpenGenerator] = useState<boolean>(false)
    // Modules
    const [usedModule, setUsedModule] = useState<any>("Standard")
    const [verifiedGtin, setVerifiedGtin] = useState<boolean | string | null>(null)

    // Creation availability
    const blockQRCodeCreation = (
        !product.name ||
        (!gs1 && (!product.default_url || !regexValidUrl(product.default_url))) ||
        ((gs1 && !verifiedGtin) || (gs1 && (!product.default_url || !regexValidUrl(product.default_url))))
    )

    const canProceed = (
        product.name === "" ||
        !brands ||
        !ranges ||
        !product.range ||
        !regexValidUrl(product.default_url) ||
        !product.qrcode ||
        (gs1 && !verifiedGtin && !editing)
    )

    // check type
    useEffect(() => {
        if (gs1) setUsedModule("GS1")
        else setUsedModule("Standard")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    // Ranges list hydration on Brand change
    useEffect(() => {
        if (!!selectedBrand) {
            let _rangesInBrand = ranges.filter((range: any) => range.brand.id === selectedBrand)
            setRangesInBrand(_rangesInBrand)
            setProduct({...product, brand: selectedBrand})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBrand])

    // Brand/Range selector fallback case for edition on range change
    useEffect(() => {
        if (ranges && product.range && selectedBrand === "") {
            let range = ranges.filter((rng: any) => rng.id === product.range)
            setSelectedBrand(range[0].brand.id)
            setProduct({...product, brand: range[0].brand.id})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ranges, product.range])

    // Fetch QR Code preview
    useEffect(() => {
        if (!!product.qrcode && !!product.type && !qrcodePreview) {
            if (product.type === "GS1" || gs1) {
                // GS1 behaviour
                getGS1QRCode(product.qrcode).then((res: any) => {
                    setQRCodePreview(res.preview)
                    setQRCode(res.data)
                })
            } else if (product.type === "Standard" || !gs1 || product.type === "") {
                getSingleQRCode(product.qrcode).then((res: any) => {
                    if (res.data) setQRCode(res.data)
                    if (res.preview) setQRCodePreview(res.preview)
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product.qrcode, product.type])

    // (Standard) When QR Code is present but default_url isn't
    useEffect(() => {
        if (!!qrcode && !product.default_url) setProduct({...product, default_url: qrcode.full_url})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [qrcode])

    const handleQRCodeCreation = () => {
        window.scrollTo(0,0)
        setOpenGenerator(true)
    }

    const handleGS1LookUp = () => {
        verifyGTIN(GS1Product).then((res: any) => {
            if (res) setVerifiedGtin(res.GS1ShortURL[0].short_url)
            else setVerifiedGtin(false)
        })
    }

    // Specific sections
    const renderParentsForm = () => {
        return (
            <Paper variant={"outlined"} sx={styles.subSection}>
                <Grid container justifyContent={"center"} spacing={selectedBrand !== "" ? 2 : 0}>
                    <Grid item xs={12} md={6}>
                        <Stack direction={"row"}>
                            <BrandsLogo className={"preSelectIcon"} />
                            <FormControl fullWidth required>
                                <InputLabel id={"brand-selector"}>{t("products:parent_brand")}</InputLabel>
                                <Select
                                    fullWidth
                                    label={t("products:parent_brand")}
                                    labelId={"brand-selector"}
                                    onChange={(e) => {
                                        setSelectedBrand(e.target.value)
                                        setProduct({...product, range: ""})
                                    }}
                                    value={selectedBrand}
                                    error={!selectedBrand}
                                >
                                    {!!brands && brands.map((brand: any) => {
                                        return (
                                            <MenuItem key={brand.id} value={brand.id}>{brand.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                                <FormHelperText sx={{ display: selectedBrand ? "none" : "flex" }}>{t("products:select_brand_mandatory")}</FormHelperText>
                            </FormControl>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Collapse in={selectedBrand !== ""}>
                            <Stack direction={"row"}>
                                <RangesLogo className={"preSelectIcon"} />
                                <FormControl fullWidth sx={{ display: selectedBrand ? "block" : "none" }} required>
                                    <InputLabel id={"range-selector"}>{t("products:parent_range")}</InputLabel>
                                    <Select
                                        fullWidth
                                        label={t("products:parent_range")}
                                        labelId={"brand-selector"}
                                        onChange={(e) => setProduct({...product, range: e.target.value})}
                                        value={product.range}
                                        error={!product.range}
                                    >
                                        {!!rangesInBrand && rangesInBrand.map((range: any) => {
                                            return (
                                                <MenuItem key={range.id} value={range.id}>{range.name}</MenuItem>
                                            )
                                        })}

                                        {
                                            (!rangesInBrand || rangesInBrand.length === 0) &&
                                            <MenuItem value={""} disabled>{t("products:no_range_created")}</MenuItem>
                                        }
                                    </Select>
                                    <FormHelperText sx={{ display: product.range ? "none" : "flex" }}>{t("products:select_range_mandatory")}</FormHelperText>
                                </FormControl>
                            </Stack>
                        </Collapse>
                    </Grid>
                </Grid>
            </Paper>
        )
    }

    const renderInformationSection = () => {
        return (
            <Paper variant={"outlined"} sx={styles.mainSection}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1"><b>{t("products:product_name")}</b></Typography>
                        <TextField
                            error={product.name === ""}
                            helperText={product.name === "" ? t("products:product_name_empty_error") : ""}
                            size="small"
                            variant="outlined"
                            fullWidth
                            value={product.name}
                            onChange={(e) => {
                                setProduct({...product, name: e.target.value})
                            }}/>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="subtitle1"><b>{t("products:product_sku")}</b></Typography>
                        <Typography variant="body2" gutterBottom>{t("products:product_sku_label")}</Typography>
                        <TextField
                            size="small"
                            variant="outlined"
                            value={product.sku}
                            fullWidth
                            onChange={(e) => {
                                setProduct({...product, sku: e.target.value})
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>
        )
    }

    const renderPackagingModules = () => {

        const renderModuleOptions = () => {

            const renderGeolocationOptions = () => {
                return (
                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel sx={{ color: "initial!important" }}><b>{t("products:use_geolocation_webapp")}</b></FormLabel>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={!product.bypass_app}
                                        onChange={() => setProduct({...product, bypass_app: !product.bypass_app})}
                                    />
                                }
                                label={product.bypass_app ? t("products:using_geolocation") : t("products:ignoring_geolocation")}
                            />
                        </FormControl>
                    </Grid>
                )
            }

            const renderGS1Options = () => {

                if (gs1 && product.qrcode !== "") return (
                    <>
                        {renderGeolocationOptions()}
                        <Typography sx={{ mt: 2, ml: 2 }}><i>{t("products:cant_modify_gs1_information")}</i></Typography>
                    </>
                )

                return (
                    <>
                        {renderGeolocationOptions()}
                        <ProductsGS1Form {...{usedModule, GS1Product, setGS1Product, handleGS1LookUp, verifiedGtin, t}} />
                    </>
                )
            }

            if (gs1) return renderGS1Options()
            else return renderGeolocationOptions()
        }

        return (
            <PaperLabelled
                bold
                label={gs1 ? t("products:gs1_information") : t("products:geolocation_information")}
                paperPadding={3}
                background={"#efefef"}
            >
                <Grid container justifyContent={{ xs: "flex-start", lg: "center" }} spacing={2}>
                    {renderModuleOptions()}
                </Grid>
            </PaperLabelled>
        )
    }

    const renderDefaultUrlForm = () => {
        return (
            <Collapse in={true}>
                <Typography variant="subtitle1"><b>{t("products:product_default_url")}</b></Typography>
                <Typography variant="body2" gutterBottom>{t("products:product_default_url_label")}</Typography>
                <TextField
                    size="small"
                    variant="outlined"
                    value={product.default_url}
                    onChange={(e) => setProduct({...product, default_url: e.target.value})}
                    multiline
                    fullWidth
                    placeholder={"https://"}
                    disabled={!!product.qrcode && !!qrcode && creating}
                    error={!product.default_url || !regexValidUrl(product.default_url)}
                    helperText={!product.default_url && t("products:default_url_mandatory")}
                />
                <Divider sx={{ my: 2 }} />
            </Collapse>

        )
    }

    const renderQRCodeForm = () => {

        const renderQRCodePreview = () => {
            if (qrcodePreview) return <img src={qrcodePreview} width="100px" height="100px" alt={"qrcode-preview"}/>
            else return <Skeleton height={100} width={100} variant={"rectangular"} />
        }

        const renderQRCodeFormContent = () => {
            if ((!!product.qrcode && !!qrcode) || (!!product.qrcode && qrcodePreview && product.type === "GS1")) {
                return (
                    <Paper elevation={4} sx={{ p: 2 }}>
                        <Grid container justifyContent={{ xs: "center", md: "flex-start"}} spacing={5}>
                            <Grid item xs={10} md={"auto"}>
                                {renderQRCodePreview()}
                            </Grid>
                            <Grid item xs={10} md={6} sx={styles.qrcodePreviewTextWrapper}>
                                { creating && <Typography fontWeight={700}>{t("products:qrcode_successfully_created")}</Typography> }
                                <Typography>{qrcode?.label}</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                )
            } else {
                return (
                    <Button
                        onClick={() => handleQRCodeCreation()}
                        disabled={blockQRCodeCreation}
                        variant={"contained"}
                        color={"secondary"}
                        disableElevation
                        size={"large"}
                        fullWidth
                        startIcon={<QrCode2 />}
                    >
                        {
                            (gs1 && !verifiedGtin) ? t("products:look_up_before_creating_qrcode")
                            : !product.name ? t("products:name_product_before_qrcode")
                            : t("products:create_product_qrcode")
                        }
                    </Button>
                )
            }
        }

        return (
            <>
                <Typography variant="subtitle1"><b>{t("products:product_qrcode")}</b></Typography>
                <Typography variant="body2" gutterBottom>{t("products:product_qrcode_label")}</Typography>
                { (gs1 && !product.qrcode) &&
                    <Typography color={"error"} variant="body2" gutterBottom>
                        {t("products:gs1_edition_alert")}
                    </Typography>
                }
                {renderQRCodeFormContent()}
            </>
        )
    }

    // loading
    if (editing && product.id === "") return <Loading />

    return (
        <>
            <Grid container spacing={3} sx={styles.editorGrid} justifyContent="center">

                <Grid item xs={12}>{renderParentsForm()}</Grid>
                <Grid item xs={12}>{renderInformationSection()}</Grid>
                <Grid item xs={12}>{renderPackagingModules()}</Grid>

                <Grid item xs={12}>
                    <Paper variant={"outlined"} sx={styles.subSection}>
                        {renderDefaultUrlForm()}
                        {renderQRCodeForm()}
                    </Paper>
                </Grid>

                <Grid item xs={12} pt={2} sx={styles.alignCenter}>
                    <Button
                        onClick={creating ? onCreate : editing ? onEdit : () => {return}}
                        disabled={canProceed}
                        variant={"contained"}
                        disableElevation
                        size={"large"}
                        sx={styles.submitButton}
                        startIcon={editing ? <Save /> : <Add />}
                    >
                        {creating ? t("products:product_create") : editing ? t("products:product_edit") : t("common:loading")}
                    </Button>
                </Grid>
            </Grid>

            <QRCodeGeneratorDialog
                open={openGenerator}
                onClose={() => setOpenGenerator(false)}
                {...{
                    gs1,
                    product, setProduct,
                    usedModule,
                    verifiedGtin,
                    t
                }}
            />
        </>
    )
}