import React, {createContext, useContext, useEffect, useState} from "react";
import {useUser} from "./UserProvider";

// TODO: This component is a draft of a larger permissions manager
// Knowing that most of the permissions are managed by the GQL backend, we don't need
// a lot of complexity to start with, so most of the component will be commented
// while waiting for a better use later on.
// The canCreate const has been moved here for more flexibility

const PermissionsContext = createContext<any>(null)

export const usePermissions = () => useContext(PermissionsContext)

export function PermissionsProvider({children}: any) {

    const {user, org} = useUser()

    // const [usagePermissions, setUsagePermissions] = useState<any>()
    // user constants
    const [trueAccountTier, setTrueAccountTier] = useState<string>("")
    const [userRole, setUserRole] = useState<string>("")
    // loading
    const [permissionsLoading, setPermissionsLoading] = useState<boolean>(true)

    useEffect(() => {
        if (!!user) {
            if (!!org && !!org.org_account_type) {
                setTrueAccountTier(org.org_account_type)
                setUserRole(org.role)
            } else setTrueAccountTier(user.account_type)
        }
    }, [user, org]);

    useEffect(() => {
        if (!!trueAccountTier) mapPermissions()
    }, [trueAccountTier]);

    // map permissions
    const mapPermissions = () => {
        setPermissionsLoading(false)
    }

    // check permissions
    // const hasUsage = (permission: any) => {
    //
    // }

    // Account checkers
    // // Types : "free" | "standard" | "gold" | "platinum" | "standalone"
    // const isAccountTier = (tier: string[]) => {
    //     return Boolean(tier.find((e: string) => trueAccountTier === e))
    // }

    // // Types : "member" | "admin" | "analyst" | "owner"
    const isAccountRole = (role: string[]) => {
        if (!userRole) return false
        return Boolean(role.find((e: string) => userRole.toLowerCase() === e))
    }

    return (
        <PermissionsContext.Provider value={{
            usePermissions, permissionsLoading,
            // checkers
            isAccountRole,
        }}>
            {children}
        </PermissionsContext.Provider>
    )
}