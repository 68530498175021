import React, {useState, useEffect} from "react"
import {Link} from "react-router-dom";
// MUI
import {Box, Divider, Grid, Button, Typography, Collapse, Stack, Alert} from "@mui/material";
import {Add, FilterAltOff} from "@mui/icons-material";
// Data
import {useUser} from "@/context/UserProvider";
import {useData} from "@/context/PackagingDataProvider";
// Components
import {DownloadSpreadsheetDialog, OrganisationFilterSelect} from "@/components/UI";
import {BrandsDataTable} from "@/components/Brands";
// Translation
import {useTranslation} from "react-i18next";
import {usePermissions} from "@/context/PermissionsProvider";

export default function BrandsTab() {

    const {t} = useTranslation(["brands", "common"])
    const {organisation} = useUser()
    const {isAccountRole} = usePermissions()
    const {brands, getBrands, rangesInBrands, brandsLogos, commonFilters, setCommonFilters, clearCommonFilters} = useData()
    // Batch dialog
    const [openSpreadsheetDialog, setOpenSpreadsheetDialog] = useState<boolean>(false)

    useEffect(() => {
        if (!!organisation && !brands) {
            if (!commonFilters.organisation) getBrands()
            else getBrands(commonFilters.organisation)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisation])

    useEffect(() => {
        if (!!commonFilters.organisation) getBrands(commonFilters.organisation)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commonFilters.organisation])

    const renderFilters = () => {

        const clearFilters = () => {
            clearCommonFilters()
            getBrands()
        }

        return (
            <>
                <Grid item xs={12}>
                    <Collapse in={true}>
                        <Grid container justifyContent={"flex-start"} spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant={"body1"} fontWeight={600}>
                                    {t("filters")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <OrganisationFilterSelect
                                    small
                                    organisationFilter={commonFilters.organisation}
                                    setOrganisationFilter={(el: any) => setCommonFilters({...commonFilters, organisation: el})}
                                    {...{t}}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Collapse in={Boolean(commonFilters.organisation)}>
                                    <Alert
                                        severity={"info"}
                                        sx={{ pb: 1 }}
                                        action={
                                            <Button
                                                onClick={() => clearFilters()}
                                                size={"small"} disableElevation
                                                variant={"contained"}
                                                color={"secondary"}
                                                startIcon={<FilterAltOff />}
                                            >
                                                {t("clear_filters")}
                                            </Button>
                                        }
                                    >
                                        {t("filters_are_active")}
                                    </Alert>
                                </Collapse>
                            </Grid>
                        </Grid>
                    </Collapse>
                </Grid>
            </>
        )
    }

    return (
        <>
            <Box sx={{ p: 2 }}>
                <Grid container justifyContent={{ xs: "center" }} rowSpacing={3} columnSpacing={1}>
                    <Grid item xs={12}>
                        <Typography variant={"h4"} component={"p"}>
                            {t("brands_list")}
                        </Typography>
                    </Grid>
                    <Grid item xs={"auto"} md={12}>
                        <Stack direction={"row"} spacing={2}>
                            <Button
                                variant={"contained"}
                                disableElevation
                                startIcon={<Add />}
                                component={Link}
                                to={"/brands/create"}
                                disabled={isAccountRole(["analyst"])}
                            >
                                {t("brand_create")}
                            </Button>
                            <Button
                                color={"secondary"}
                                variant={"contained"}
                                disableElevation
                                onClick={() => setOpenSpreadsheetDialog(true)}
                            >
                                {t("download_spreadsheet")}
                            </Button>
                        </Stack>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    {renderFilters()}

                    <Grid item xs={12}>
                        <BrandsDataTable disableActions={isAccountRole(["analyst"])} {...{brands, getBrands, rangesInBrands, brandsLogos, t}} />
                    </Grid>
                </Grid>

                <DownloadSpreadsheetDialog
                    open={openSpreadsheetDialog}
                    onClose={() => setOpenSpreadsheetDialog(false)}
                    {...{t}}
                />
            </Box>
        </>
    )
}