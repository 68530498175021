import React, {useEffect, useState} from "react"
// MUI
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, Button, Stack} from "@mui/material";
import {ChevronRight} from "@mui/icons-material";
// Translation
import {useTranslation} from "react-i18next";
// Components
import {BrandsEditor} from "@/components/Brands";
import {RangesEditor} from "@/components/Ranges";
import {ProductsEditor} from "@/components/Products";
// @ts-ignore
import PackagingIcon from "@/assets/png/packaging.png"
// Data
import {useData} from "@/context/PackagingDataProvider";


export default function IntroductionStepper(props: any) {

    const {t} = useTranslation(["brands", "ranges", "products", "common"])
    const {firstVisit, setFirstVisit} = props
    const {
        brands, getBrands,
        ranges, getRanges,
        createAny
    } = useData()

    // Steps tracking
    const [step, setStep] = useState<number>(0)
    // Content tracking
    const [brand, setBrand] = useState<any>({
        id: "",
        name: "",
        description: "",
        logo: "",
        logo_id: ""
    })

    const [range, setRange] = useState<any>({
        brand: "",
        id: "",
        name: "",
        description: "",
        logo: "",
        logo_id: "",
    })

    const [product, setProduct] = useState<any>({
        brand: "",
        range: "",
        id: "",
        name: "",
        qrcode: "",
        bypass_app: false,
        sku: "",
        default_url: "",
        type: "Standard"
    })

    // GS1
    const [GS1Product, setGS1Product] = useState<any>({
        primaryKey: "01",
        primaryKeyValue: "",
        key_identifiers: [
            { key: "22", value: "" },
            { key: "10", value: "" },
            { key: "21", value: "" },
        ],
        dataAttributes: [
            { key: "", value: "" }
        ]
    })

    // Check if a part of the tutorial has been made, and change display accordingly
    useEffect(() => {
        if (!!firstVisit) {
            if (firstVisit === "ranges") setStep(2)
            if (firstVisit === "products") setStep(3)
            if (firstVisit === "end") setStep(4)
        }
    }, [firstVisit])

    // Gather data for each panel
    useEffect(() => {
        if (step === 2) getBrands()
        if (step === 3) {
            getBrands()
            getRanges()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step])

    // Use newly created assets for next assets
    useEffect(() => {
        if (step === 2 && !!brands && brands[0]) setRange({...range, brand: brands[0].id })
        if (step === 3 && !!ranges && ranges[0]) setProduct({...product, range: ranges[0].id})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step, brands, ranges])

    // GS1 - clearing key qualifiers on primary key change
    useEffect(() => {
        setGS1Product({...GS1Product, key_identifiers: []})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [GS1Product.primaryKey])

    // handlers
    const createBrand = () => createAny(brand, "brand").then((res: any) => { if (res) setStep(2) })
    const createRange = () => createAny(range, "range").then((res: any) => { if (res) setStep(3) })
    const createProduct = () => createAny(product, "product").then((res: any) => { if (res) setStep(4) })

    // display steps content
    const renderStepsTitle = () => {
        if (step === 0) return t("welcome")
        if (step === 1) return t("brands:brand_create")
        if (step === 2) return t("ranges:range_create")
        if (step === 3) return t("products:product_create")
        if (step === 4) return t("good_to_go")
        return ""
    }

    const renderStepsContent = () => {

        const packagingIntroduction = () => {
            return (
                <Grid container justifyContent={"center"} spacing={2} sx={{ my: 2 }}>
                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                        <Stack direction={"row"} justifyContent={"center"}>
                            <img src={PackagingIcon} alt={"unitag packaging"} height={60} width={60} />
                            <Typography variant={"h5"} component={"p"} sx={{ mt: 2, ml: 2 }}>
                                {t("unitag_packaging")}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={11} md={7}>
                        <Typography variant={"body1"} align={"center"}>{t("packaging_introduction")}</Typography>
                    </Grid>
                    <Grid item xs={11} md={7}>
                        <Typography variant={"body1"} align={"center"}>{t("packaging_step_by_step")}</Typography>
                    </Grid>
                    <Grid item xs={11} md={7}>
                        <Typography variant={"body1"} align={"center"}>{t("packaging_step_by_step_2")}</Typography>
                    </Grid>
                </Grid>
            )
        }

        if (step === 0) return packagingIntroduction()
        if (step === 1) return (<BrandsEditor creating onCreate={() => createBrand()} {...{brand, setBrand, t}} />)
        if (step === 2) return (<RangesEditor creating onCreate={() => createRange()} {...{brands, range, setRange, t}} />)
        if (step === 3) return (<ProductsEditor creating gs1={Boolean(product.type === "GS1")} onCreate={() => createProduct()} {...{brands, ranges, product, setProduct, GS1Product, setGS1Product, t}} />)
        if (step === 4) return (<Typography align={"center"}>{t("good_to_go_intro")}</Typography>)
        return <></>
    }

    const renderStepsActions = () => {

        const commonNextButtonProps: any = {
            variant: "contained",
            disableElevation: true,
            endIcon: <ChevronRight />,
            component: "button",
            color: "primary"
        }

        if (step === 0) return (
            <>
                <Button onClick={() => setFirstVisit(undefined)}>{t("skip_intro")}</Button>
                <Button {...commonNextButtonProps} onClick={() => setStep(1)} >{t("next")}</Button>
            </>
        )

        if (step === 4) return <Button onClick={() => setFirstVisit(undefined)} {...commonNextButtonProps}>{t("finish")}</Button>
    }

    // display module selector for specific users
    const moduleSelector = () => {
        return <></>
        // if (modules.gs1 && modules.geoloc && step === 3) {
        //     return (
        //         <PaperLabelled label={t("common:module_selector")} sx={{ my: 3 }}>
        //             <Grid container justifyContent={"center"} spacing={3}>
        //                 <Grid item xs={10}>
        //                     <Typography align={"center"}>{t("common:module_selector_intro")}</Typography>
        //                 </Grid>
        //
        //                 <Grid item xs={12} sm={6} sx={{ textAlign: "center" }}>
        //                     <Button
        //                         variant={product.type !== "GS1" ? "contained" : "outlined"}
        //                         disableElevation
        //                         onClick={() => setProduct({...product, type: "Standard"})}
        //                         sx={{ minWidth: 200 }}
        //                     >
        //                         {t("common:standard_product")}
        //                     </Button>
        //                 </Grid>
        //                 <Grid item xs={12} sm={6} sx={{ textAlign: "center" }}>
        //                     <Button
        //                         variant={product.type === "GS1" ? "contained" : "outlined"}
        //                         disableElevation
        //                         onClick={() => setProduct({...product, type: "GS1"})}
        //                         sx={{ minWidth: 200 }}
        //                     >
        //                         {t("common:gs1_product")}
        //                     </Button>
        //                 </Grid>
        //             </Grid>
        //         </PaperLabelled>
        //     )
        // }
        // return <></>
    }

    return (
        <>
            <Dialog open={Boolean(firstVisit)} maxWidth={"md"} fullWidth>
                <DialogTitle>{renderStepsTitle()}</DialogTitle>
                <DialogContent className={"fancyScroll"}>
                    {moduleSelector()}
                    {renderStepsContent()}
                </DialogContent>
                <DialogActions sx={{ mb: 2, mx: 2 }}>{renderStepsActions()}</DialogActions>
            </Dialog>
        </>
    )
}