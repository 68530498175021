import React, {useRef, useState} from "react"
import {useNavigate} from "react-router-dom"
// Primereact
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {ContextMenu} from "primereact/contextmenu";
// MUI
import {Grid, Tooltip, Typography, IconButton, Skeleton, Paper} from "@mui/material";
import {Edit, Delete, Warning} from "@mui/icons-material";
// Components
import {DeleteAnyDialog} from "@/components/UI";
// Data
import {useData} from "@/context/PackagingDataProvider";
// Utils
import {formatJSIso8601ToHumanString} from "@/utils/timeFunctions";

export default function BrandsDataTable(props: any) {

    const {brands, rangesInBrands, brandsLogos, disableActions, t} = props
    const {deleteAny, getBrands} = useData()
    const navigate = useNavigate()

    const contextMenu = useRef<ContextMenu>(null)

    const [selection, setSelection] = useState<any>(null)
    const [deletion, setDeletion] = useState<any>(null)
    // Delete dialog
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)


    const contextMenuModel = [
        { label: t("common:edit"), icon: <Edit fontSize={"small"} sx={{ mr: 1 }} />, command: () => navigate("/brands/edit", { state: { ...selection } }), disabled: disableActions},
        { separator: true },
        { label: t("common:delete"), icon: <Delete fontSize={"small"} sx={{ mr: 1 }} />, command: () => handleDelete(selection), disabled: disableActions}
    ]

    // Handlers
    const handleDelete = (brand: any) => {
        setDeletion(brand)
        setOpenDeleteDialog(true)
    }

    // CRUD
    const deleteBrand = () => {
        deleteAny(deletion, "brand").then((deleted: any) => { if (!!deleted) getBrands() })
        setOpenDeleteDialog(false)
        setDeletion(null)
    }

    // DataTable
    const dateBodyTemplate = (rowData: any) => {
        return <Typography>{formatJSIso8601ToHumanString(rowData.created_at)}</Typography>
    }

    const actionBodyTemplate = (rowData: any) => {

        const handleEdit = () => {
            navigate("/brands/edit", { state: { ...rowData } })
        }

        return (
            <Grid container style={{minWidth: '100px'}}>
                <Grid item>
                    <Tooltip placement={"top"} arrow title={`${t("edit")}`}>
                        <IconButton
                            aria-label="edit"
                            onClick={handleEdit}
                            disabled={disableActions}
                        ><Edit /></IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip placement={"top"} arrow title={`${t("delete")}`}>
                        <IconButton
                            aria-label="delete"
                            value={rowData.uuid}
                            onClick={() => handleDelete(rowData)}
                            disabled={disableActions}
                        ><Delete/></IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        );
    }

    const rangesBodyTemplate = (rowData: any) => {
        if (!!rangesInBrands) {
            if (!!rangesInBrands[rowData.id]) {
                return <Typography>{rangesInBrands[rowData.id]}</Typography>;
            } else {
                return (
                    <Grid container spacing={1}>
                        <Grid item>
                            <Typography>{rangesInBrands[rowData.id]}</Typography>
                        </Grid>
                        <Grid item>
                            <Tooltip placement={"top"} arrow title={`${t("range_required")}`}>
                                <Warning/>
                            </Tooltip>
                        </Grid>
                    </Grid>
                );
            }
        }
    }

    const logoTemplate = (rowData: any) => {

        if (rowData.logo_id === "" || !rowData.logo_id) return <></>

        if (!!brands) {
            if (
                !brandsLogos ||
                brandsLogos[rowData.id] === undefined ||
                brandsLogos[rowData.id] === null
            ) return <Skeleton variant="circular" width={50} height={50}/>

            return <img width={'50px'} height={'auto'} src={brandsLogos[rowData.id] as any as string} alt="brand-logo"/>
        }
        return <></>
    }

    return (
        <>
            <>
                <Paper variant={"outlined"} sx={{ borderRadius: 5, overflow: "hidden" }}>
                    <ContextMenu
                        model={contextMenuModel}
                        ref={contextMenu}
                        onHide={() => setSelection(null)}
                        breakpoint={"600px"}
                    />
                    <DataTable
                        value={brands}
                        rowHover
                        emptyMessage={t("no_elements_found")}
                        onContextMenu={(e) => contextMenu.current?.show(e.originalEvent)}
                        contextMenuSelection={selection}
                        onContextMenuSelectionChange={(e) => setSelection(e.value)}
                    >
                        <Column header={t("logo")} bodyStyle={{textAlign: 'center'}} body={logoTemplate}/>
                        <Column field="name" header={t("label")} sortable/>
                        <Column field="created_at" header={t("created_at")} body={dateBodyTemplate} sortable/>
                        <Column field="description" header={t("description")}/>
                        <Column header={t("ranges")} body={rangesBodyTemplate}/>
                        <Column header={t("actions")} body={actionBodyTemplate}/>
                    </DataTable>
                </Paper>

                <DeleteAnyDialog
                    open={openDeleteDialog}
                    onClose={() => setOpenDeleteDialog(false)}
                    onDelete={() => deleteBrand()}

                    assetName={deletion?.name}
                    text={t("delete_brand_alert")}
                    {...{t}}
                />
            </>
        </>
    )
}