import React, {createContext, useContext, useEffect, useState} from "react"
// API
import {APIDelete, APIGet, APIGetBlob, APIPost, APIPostBlob, APIPostFile, APIPut} from "@api";
// JWT
import {enqueueSnackbar} from "notistack";
// Interfaces
import {PackagingApps, PackagingBrand, PackagingProduct, PackagingProductPaginated, PackagingRange} from "@/interfaces";
// Context
import {useUser} from "@/context/UserProvider";
import {useTranslation} from "react-i18next";
import {checkIfEmptyArray} from "@/utils";
import Loading from "@/components/Loading/Loading";

const {REACT_APP_API_URL} = process.env

const DataContext = createContext<any>(null)
export const useData = () => useContext(DataContext)

export default function PackagingDataProvider({children}: any) {

    const {token, organisation} = useUser()
    const {t} = useTranslation("common")

    let init = { brands: false, ranges: false, products: false }

    // Error hooks
    const handleSuccess = (message: string) => enqueueSnackbar(message, { variant: "success" })
    const handleError = (message: string) => enqueueSnackbar(message, { variant: "error" })

    const [loading, setLoading] = useState<boolean>(true)
    // First visit checker
    const [firstVisit, setFirstVisit] = useState<any>(false)
    // data - watch for indentation, indicating in which section we are!
    const [brands, setBrands] = useState<any>(null)
        const [brandsLogos, setBrandsLogos] = useState<any>(null)
        const [rangesInBrands, setRangesInBrands] = useState<Record<string, number>>({})
        // This is actually RANGES count for BRANDS - should be its own item in <Brands>
    const [ranges, setRanges] = useState<any>(null)
        const [rangesLogos, setRangesLogos] = useState<any>(null)
    const [products, setProducts] = useState<any>(null)
        const [totalProducts, setTotalProducts] = useState<number>(0)
    const [apps, setApps] = useState<any>(null)
    // Generator specific data sets
    const [domains, setDomains] = useState<any>(null)
    const [qrcodeTemplates, setQRCodesTemplates] = useState<any>(null)
    // Filters shared across panels
    const [commonFilters, setCommonFilters] = useState<any>({
        organisation: ""
    })
    const clearCommonFilters = () => {
        setCommonFilters({
            organisation: ""
        })
    }

    useEffect(() => {
        if (!!token && !!organisation) {
            if (!brands) getBrands().then()
            if (!ranges) getRanges().then()
            if (!products) getProducts().then()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, organisation])

    useEffect(() => {
        if (!!token && !!organisation && firstVisit !== undefined && brands !== null && ranges !== null && products !== null) {
            if (checkIfEmptyArray(brands)) { setFirstVisit("brands"); setLoading(false); init.brands = true; return; }
            else if (checkIfEmptyArray(ranges)) { setFirstVisit("ranges"); setLoading(false); init.ranges = true; return; }
            else if (checkIfEmptyArray(products)) { setFirstVisit("products"); setLoading(false); init.products = true; return; }
            else if (firstVisit === "products") setFirstVisit("end");
            else setFirstVisit(undefined); setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brands, ranges, products, organisation, token])

    // ANY
    const createAny = async (object: any, type: string) => {

        if ((!object && type !== "app") || !type) return

        let created

        let body
        let refresher: any
        let errorMessage: string

        switch(type) {
            case "brand":
                body = {
                    name: object.name,
                    description: object.description,
                    logo_asset_id: object.logo_id,
                }
                refresher = () => getBrands()
                errorMessage = "error_create_brand"
                break;
            case "range":
                body = {
                    name: object.name,
                    description: object.description,
                    brand_id: object.brand,
                    logo_asset_id: object.logo_id,
                }
                refresher = () => getRanges()
                errorMessage = "error_create_range"
                break;
            case "product":
                body = {
                    qr_code_id: object.qrcode,
                    range_id: object.range,
                    name: object.name,
                    sku: object.sku,
                    default_url: object.default_url,
                    filters: null,
                    bypass_app: object.bypass_app,
                    type: object?.type
                }
                refresher = () => getProducts()
                errorMessage = "error_create_product"
                break;
            case "app":
                body = {}
                refresher = () => getApps()
                errorMessage = "error_create_app"
                break;
            case "url":
                body = {
                    country_code: object.country.code,
                    destination_url: object.destination_url,
                    language_code: object.language.code,
                    product_id: object.product_id
                }
                refresher = () => getSingleProductUrls(object.product_id)
                errorMessage = "error_create_redirection_url"
                break;
            default:
                return;
        }

        await APIPost<any>(REACT_APP_API_URL + "/packaging/" + type, body, token)
            .then((data: any) => {
                if (data.ok) {
                    refresher()
                    created = true
                    handleSuccess(`${t(type)} ${t("created")}`)
                } else {
                    handleError(t(errorMessage))
                }
            }).catch(() => handleError(t(errorMessage)));

        return created
    }

    const deleteAny = async (toDelete: any, type: any) => {

        if (!toDelete || !type) handleError(t("error_delete_request"))

        let deleted
        let endpoint
        let errorMessage: string
        let body

        switch (type) {
            case "filter":
                endpoint = "/" + type
                body = {
                    id: toDelete.id,
                    final_url: toDelete.destination_url
                }
                errorMessage = t("error_deleting") + " " + t("redirection_url")
                break;
            default:
                endpoint = "/packaging/" + type
                body = {
                    id: toDelete.id,
                    name: toDelete.name
                }
                errorMessage = t("error_deleting") + " " + t(type)
        }

        await APIDelete<any>(REACT_APP_API_URL + endpoint, body, token).then((data) => {
                if (data.ok) {
                    deleted = true
                    handleSuccess(`${t(type)} ${t("deleted")}` )
                } else {
                    handleError(errorMessage)
                }
            }).catch(() => handleError(errorMessage));

        return deleted
    }

    const editAny = async (object: any, type: string) => {

        if (!object || !type) return

        let updated

        let body
        let refresher: any
        let errorMessage: string

        // specific case for URLs call
        if (type.includes("url/")) {
            body = {
                id: type.replace("url/", ""),
                country_code: object.country.code,
                destination_url: object.destination_url,
                language_code: object.language.code,
                product_id: object.product_id
            }
            refresher = () => null
            errorMessage = "error_update_redirection_url"
        } else {
            switch(type) {
                case "brand":
                    body = {
                        id: object.id,
                        name: object.name,
                        description: object.description,
                        logo_asset_id: object.logo_id,
                    }
                    refresher = () => getBrands()
                    errorMessage = "error_update_brand"
                    break;
                case "range":
                    body = {
                        id: object.id,
                        name: object.name,
                        description: object.description,
                        brand_id: object.brand,
                        logo_asset_id: object.logo_id,
                    }
                    refresher = () => getRanges()
                    errorMessage = "error_update_range"
                    break;
                case "product":
                    body = {
                        id: object.id,
                        qr_code_id: object.qrcode,
                        range_id: object.range,
                        brand_id: object.brand,
                        name: object.name,
                        sku: object.sku,
                        default_url: object.default_url,
                        filters: object.filters,
                        bypass_app: object.bypass_app,
                        type: object?.type
                    }
                    refresher = () => getProducts()
                    errorMessage = "error_update_product"
                    break;
                case "app":
                    body = object
                    refresher = () => getApps()
                    errorMessage="error_update_app"
                    break;
                default:
                    return;
            }
        }

        await APIPut<any>(REACT_APP_API_URL + "/packaging/" + type, body, token)
            .then((data: any) => {
                if (data.ok) {
                    refresher()
                    updated = true
                    handleSuccess(`${t(type)} ${t("saved")}`)
                } else handleError(t(errorMessage))
            }).catch(() => {handleError(t(errorMessage))});

        return updated
    }

    // Brands
    const getBrands = async (organisationFilter?: any) => {

        if (!organisation) return

        const getRangesCount = (brandUuid: any) => {
            APIGet<any>(REACT_APP_API_URL + "/packaging/brand/" + brandUuid + "/ranges/count", token).then((data: any) => {
                if (!!data.parsedBody) {
                    let _rangesInBrands = rangesInBrands;
                    _rangesInBrands[brandUuid] = data.parsedBody.count;
                    setRangesInBrands({...rangesInBrands, ..._rangesInBrands});
                }
            }).catch(() => handleError(t("error_get_range_count")));
        }

        const getBrandsLogos = (brandsList: any) => {
            for (const brand of brandsList) {
                if (brand.logo_id) {
                    APIPostBlob<any>(REACT_APP_API_URL + "/asset/download/logo", {
                        asset_uuid: brand.logo_id,
                    }, token).then((data) => {
                        if (data.ok && data.rawBlob !== undefined) {
                            let img = window.URL.createObjectURL(data.rawBlob);
                            let _brandsLogo = brandsLogos;
                            _brandsLogo[brand.id] = img;
                            setBrandsLogos({...brandsLogos, ..._brandsLogo});
                        }
                    }).catch(() => {
                        handleError(t("error_get_logos"))
                    });
                }
            }
        }

        let orgUuid = !!organisationFilter ? `?org=${organisationFilter}` : ""

        await APIGet<Array<PackagingBrand>>(REACT_APP_API_URL +  `/packaging/brands${orgUuid}`, token)
            .then((data) => {
                if (!!data.parsedBody) {
                    getBrandsLogos(data.parsedBody)
                    setBrands(data.parsedBody)
                    for (const brand of data.parsedBody) {
                        getRangesCount(brand.id)
                    }
                } else setBrands([])
            }).catch(() => handleError(t("error_get_brands")))
    }

    // Ranges
    const getRanges = async (organisationFilter?: any) => {

        if (!organisation) return

        const getRangesLogos = (rangesList: any) => {
            for (const range of rangesList) {
                if (range.logo_id) {
                    APIPostBlob<any>(REACT_APP_API_URL + "/asset/download/logo", {
                        asset_uuid: range.logo_id,
                    }, token).then((data) => {
                        if (data.ok && !!data.rawBlob) {
                            let img = window.URL.createObjectURL(data.rawBlob);
                            let _rangesLogos = rangesLogos;
                            _rangesLogos[range.id] = img;
                            setRangesLogos({...rangesLogos, ..._rangesLogos});
                        }
                    }).catch(() => {
                        handleError(t("error_get_logos"))
                    })
                }
            }
        }

        let orgUuid = !!organisationFilter ? `?org=${organisationFilter}` : ""

        await APIGet<Array<PackagingRange>>(REACT_APP_API_URL + `/packaging/ranges${orgUuid}`, token)
            .then((data) => {
                if (!!data.parsedBody) {
                    getRangesLogos(data.parsedBody);
                    setRanges(data.parsedBody);
                } else setRanges([]);
            }).catch(() => {
            handleError(t("error_get_ranges"))
            setRanges([]);
        })
    }

    // Products
    const getProducts = async (filters?: any) => {

        if (!organisation) return

        let defaultParams = {
            range: "",
            brand: "",
            max_rows: "10",
            page: "1",
            search: "",
            org: "",
            type: ""
        }

        let params = new URLSearchParams(filters ? filters : {...defaultParams})

        await APIGet<PackagingProductPaginated>(REACT_APP_API_URL + "/packaging/products?" + params, token)
            .then((data) => {
                if (data.ok && !!data.parsedBody) {
                    setProducts(data.parsedBody.records);
                    setTotalProducts(data.parsedBody.total_records);
                } else {
                    setProducts([]);
                    setTotalProducts(0);
                }
            }).catch(() => {
            handleError(t("error_get_products"))
            setProducts([]);
            setTotalProducts(0);
        })
    }

    const getSingleProduct = async (productUuid: string) => {

        let response

        await APIGet<PackagingProduct>(REACT_APP_API_URL + "/packaging/product?uuid=" + productUuid, token)
            .then((data) => {
                if (data.ok && !!data.parsedBody) response = data.parsedBody
                else handleError("error_get_product")
            }).catch(() => handleError("error_get_product"));

        return response
    }

    const getSingleProductUrls = async (productUuid: string) => {

        let response

        await APIGet<any>(`${REACT_APP_API_URL}/packaging/product/${productUuid}/urls`, token)
            .then((data: any) => {
                if (data.ok && !!data.parsedBody && data.parsedBody) response = data.parsedBody})
            .catch(() => handleError(t("error_get_redirections")))

        return response
    }

    const archiveProducts = async (uuids: any) => {

        if (!uuids) return false

        let response: boolean = false
        let body = { uuids: uuids }

        await APIPut<any>(REACT_APP_API_URL + "/packaging/product/archive", body, token).then((res: any) => {
            if (!!res) response = true
            else handleError(t("error_archiving_products"))
        }).catch(() => handleError(t("error_archiving_products")))

        return response
    }

    const restoreProducts = async (uuids: any) => {

        let response: boolean = false
        let body = { uuids: uuids }

        await APIPut<any>(REACT_APP_API_URL + "/packaging/product/restore", body, token).then((res: any) => {
            if (!!res) response = true
            else handleError(t("error_restoring_products"))
        }).catch(() => handleError(t("error_restoring_products")))

        return response
    }

    // Apps management
    const getApps = (organisationFilter?: any) => {

        let organisationUuid = organisationFilter ? organisationFilter : organisation.organisation_id

        APIGet<PackagingApps[]>(REACT_APP_API_URL + "/packaging/apps?org=" + organisationUuid, token)
                .then((data) => {
                if (data.ok && !!data.parsedBody && data.parsedBody.length > 0) {
                    for (let app of data.parsedBody) {
                        if (app.brands === null) app.brands = [];
                        if (app.languages === null) app.languages = [];
                    }
                    setApps(data.parsedBody);
                } else setApps([]);
            }).catch(() => handleError(t("error_get_apps")))
    }

    const getSingleApp = async (uuid: any) => {

        if (!uuid) return

        let response

        await APIGet<PackagingApps[]>(REACT_APP_API_URL + "/packaging/app/" + uuid, token)
            .then((data) => {
                if (data.ok && !!data.parsedBody) response = data.parsedBody
                else handleError(t("error_get_app"))
            }).catch(() => handleError(t("error_get_app")))

        return response
    }

    // Images
    const handleDropImage = async (dataDrop: any) => {

        let image
        let uuid
        let url

        await APIPostFile<any>(REACT_APP_API_URL + "/asset/upload/logo", dataDrop[0], token).then((data: any) => {
            if (data.ok)
                if (data.parsedBody !== undefined) {
                    image = URL.createObjectURL(dataDrop[0]);
                    uuid = data.parsedBody.asset_id
                    url = `https://unitag-assets.s3.eu-west-1.amazonaws.com/logo/${data.parsedBody.asset_id}`
                } else handleError(t("error_upload_image"))
        }).catch(() => handleError(t("error_upload_image")));

        dataDrop = null;

        return {image: image, uuid: uuid, url: url}
    }

    const getLogo = async (logoId: any) => {

        let logo: any = undefined

        await APIPostBlob<any>(REACT_APP_API_URL + "/asset/download/logo", {
            asset_uuid: logoId,
        }, token).then((data: any) => {
            if (data.status === 200) {
                if (data.rawBlob !== undefined) {
                    logo = window.URL.createObjectURL(data.rawBlob);
                }
            } else handleError(t("error_get_logo"))
        }).catch(() => handleError(t("error_get_logo")));

        return logo
    }

    // QR Codes
    const getSingleQRCode = async (qrcodeUuid: any) => {

        let response: {data: any, preview: any} = {
            data: null,
            preview: null
        }

        await APIGet<any>(REACT_APP_API_URL + "/qrcode/" + qrcodeUuid, token)
            .then((data) => {
                if (data.ok && data.parsedBody !== undefined) response.data = data.parsedBody
                else handleError(t("error_get_product_qrcode"))
            }).catch(() => handleError(t("error_get_product_qrcode")));

        await APIGetBlob<any>(REACT_APP_API_URL + "/qrcode/" + qrcodeUuid + "/preview", token)
            .then((data: any) => {
                if (data.ok && data.rawBlob !== undefined) response.preview = URL.createObjectURL(data.rawBlob)
                else handleError(t("error_get_product_qrcode_preview"))
            }).catch(() => handleError(t("error_get_product_qrcode_preview")));

        return response
    }

    const getSingleQRCodePreview = async (qrcodeUuid: string) => {

        let response

        await APIGetBlob<any>(REACT_APP_API_URL + "/qrcode/" + qrcodeUuid + "/preview", token)
            .then((data: any) => {
                if (data.ok && data.rawBlob !== undefined) response = URL.createObjectURL(data.rawBlob)
                else handleError(t("error_get_product_qrcode_preview"))
            }).catch(() => handleError(t("error_get_product_qrcode_preview")));

        return response
    }

    const getWIPQRCodePreview = async (qrcode: any, type?: string) => {

        let response
        let querySuffix = type === "serial" ? "/qrcodes/serial/preview" : "/qrcodes/preview"

        await APIPostBlob<any>(REACT_APP_API_URL + querySuffix, qrcode, token)
            .then((data: any) => {
                if (data.ok && data.rawBlob !== undefined) {
                    if (data.status === 404) {
                        handleError(t("error_get_product_qrcode_preview"))
                        response = undefined
                    } else {
                        let generatorHeader = data.headers.get("x-unitag-generator");
                        response = {preview: URL.createObjectURL(data.rawBlob), header: generatorHeader}
                    }
                }
                else handleError(t("error_get_product_qrcode_preview"))
            }).catch(() => handleError(t("error_get_product_qrcode_preview")));

        return response
    }

    const handleDropQRCodeImage = async (type: "overlay" | "logo", input: any) => {
        // Used to combine both image-overlay and logo uploads
        // Use the `type` parameter ("overlay" || "logo") to trigger either of the behaviours

        let response

        await APIPostFile<any>(REACT_APP_API_URL + `/asset/upload/${type}`, input.target.files[0], token)
            .then((data) => {
                if (data.status === 200) response = data.parsedBody.asset_id
                else handleError(t("error_upload_image"))
            }).catch(() => handleError(t("error_upload_image")))

        return response
    }

    const getDomains = async () => {
        let result

        APIGet<any[]>(REACT_APP_API_URL + "/domains", token).then((data) => {
            if (data.status === 200 && !!data.parsedBody) {
                setDomains(data.parsedBody);
                result = data.parsedBody
            }
        }, () => {
            // removed the alert because the API sends a 401 for every other user
            // handleError(t("error_getting_domains"))
            result = []
        }).catch(() => result = []);

        return result
    }

    const getTemplates = async () => {
        APIGet<any[]>(REACT_APP_API_URL + "/qrcodes/templates", token).then((data) => {
            if (data.parsedBody !== undefined) {
                const templates = data.parsedBody;

                if (!!templates && templates.length > 0) {
                    let templatesList: any[] = [];
                    let promises = [];

                    for (let template of templates) {
                        promises.push(APIPostBlob<object>(REACT_APP_API_URL + "/qrcodes/preview", {
                            data: {
                                type: "URL",
                                resolution: "static",
                                url: "https://unitag.io",
                            },
                            settings: {
                                template_id: template.uuid,
                            }
                        }, token).then((data) => {
                            if (!!data.rawBlob) {
                                template.imgURLObject = URL.createObjectURL(data.rawBlob);
                                templatesList.push(template);
                            }
                        }));
                    }

                    Promise.all(promises).then(() => {
                        setQRCodesTemplates(templatesList);
                    });
                }
            }
        }, () => {
            handleError("error_getting_qrcode_templates")
        }).catch(() => handleError("error_getting_qrcode_templates"));
    }

    const quickDownloadSingleQRCode = async (uuid: any, label: any) => {
        await APIPostBlob<any>(REACT_APP_API_URL + "/asset/download/qrcode_bundle", {
            asset_uuid: uuid,
            format: "png",
            size: 300,
            unit: 'px',
            hasFrame: false,
            frame: {
                text_bottom: "",
                position: "bottom",
                style: "borderContained"
            }
        }, token).then((data) => {
            if (data.ok) {
                if (data.rawBlob !== undefined) {
                    let url = window.URL.createObjectURL(data.rawBlob)
                    let a = document.createElement('a')
                    a.href = url
                    a.download = label + ".png"
                    a.click()
                }
            } else {
                data.rawBlob?.text().then((text: string) => {
                    if (text.includes("UPGRADE_NEEDED")) {
                        handleError(t("error_download_qrcode_upgrade"))
                    } else {
                        handleError(t("error_download_qrcode"))
                    }
                });
            }
        }).catch(() => handleError(t("error_download_qrcode")))
    }

    const downloadSingleQRCode = async (uuid: any, label: any, file: any) => {

        let downloadPath: string = file.format === 'pdf' ? 'export_pdf' : 'qrcode_bundle';

        await APIPostBlob<any>(REACT_APP_API_URL + "/asset/download/" + downloadPath, {
            asset_uuid: uuid,
            format: file.format,
            size: Number(file.size),
            unit: 'px',
            hasFrame: false,
            frame: {
                text_bottom: "",
                position: "bottom",
                style: "borderContained"
            }
        }, token).then((data) => {
            if (data.ok) {
                if (data.rawBlob !== undefined) {
                    let url = window.URL.createObjectURL(data.rawBlob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = label + "." + file.format;
                    a.click();
                }
            } else {
                data.rawBlob?.text().then((text: string) => {
                    if (text.includes("UPGRADE_NEEDED")) {
                        handleError(t("error_download_qrcode_upgrade"));
                    } else {
                        handleError(t("error_download_qrcode"));
                    }
                });
            }
        }).catch(() => handleError(t("error_download_qrcode")))
    }

    // GS1
    const createQRCode = async (qrcode: any, type?: string) => {

        let response
        let querySuffix = type === "serial" ? "/qrcode/serial" : "/qrcode"
        if (!qrcode) return false

        await APIPost<any>(REACT_APP_API_URL + querySuffix, qrcode, token).then((res: any) => {
            if (!!res) response = res.parsedBody
            else handleError(t("error_create_qrcode"))
        }).catch(() => handleError(t("error_create_qrcode")))

        return response
    }

    const getGS1QRCode = async (uuid: any) => {
        let response: { data: any, preview: any} = {
            data: null,
            preview: null
        }

        if (!uuid) return false
        // logic on GET /qrcode/serial
        await APIGet<any>(REACT_APP_API_URL + "/qrcode/" + uuid, token).then((res) => {
            if (!!res) response.data = res.parsedBody
            else handleError("error_get_qrcode")
        }).catch(() => handleError("error_get_qrcode"))

        await APIGetBlob<any>(REACT_APP_API_URL + "/qrcode/" + uuid + "/serial/preview", token).then((res: any) => {
            if (res.ok && res.rawBlob !== undefined) response.preview = URL.createObjectURL(res.rawBlob)
            else handleError(t("error_get_product_qrcode_preview"))
        }).catch(() => handleError(t("error_get_product_qrcode_preview")))

        return response
    }

    const getGS1QRCodePreview = async (uuid: any) => {
        let response
        if (!uuid) return false
        // logic on GET /qrcode/serial
        await APIGetBlob<any>(REACT_APP_API_URL + "/qrcode/" + uuid + "/serial/preview", token).then((res: any) => {
            if (res.ok && res.rawBlob !== undefined) response = URL.createObjectURL(res.rawBlob)
            else handleError(t("error_get_product_qrcode_preview"))
        }).catch(() => handleError("aaa"))

        return response
    }

    const verifyGTIN = async (gs1Object: any) => {

        let response

        if (!gs1Object) return false

        let body = {
            gtins: [
                {
                    primary_key: gs1Object.primaryKey,
                    value: gs1Object.primaryKeyValue.replaceAll("/", "%2F"),
                    consumer_product_variant: gs1Object?.consumer_product_variant,
                    batch_number: gs1Object?.batch_number,
                    serial_number: gs1Object?.serial_number,
                    data_attributes: gs1Object.dataAttributes,
                    key_identifiers: gs1Object?.key_identifiers
                }
            ]
        }

        await APIPost<any>(REACT_APP_API_URL + "/gs1/look-up", body, token).then((data) => {
            if (!!data.parsedBody) response = data.parsedBody
            else handleError(t("error_verifying_gtin"))
        }).catch(() => handleError(t("error_verifying_gtin")))

        return response
    }

    // Misc
    const downloadSpreadsheet = async (filters: any) => {

        let body = {
            "filter_brand_uuids": filters.brands,
            "filter_range_uuids": filters.ranges
        }

        await APIPostBlob<any>(REACT_APP_API_URL + "/packaging/export", body, token).then((data) => {
            if (data.rawBlob !== undefined) {
                let url = window.URL.createObjectURL(data.rawBlob)
                let a = document.createElement('a')
                a.href = url
                a.download = 'Unitag Packaging export.xlsx'
                a.click()
            } else handleError(t("error_download_spreadsheet"))
        }).catch(() => handleError(t("error_download_spreadsheet")))
    }

    const getHealthchecks = async () => {

        let response = null

        await APIGet(process.env.REACT_APP_API_URL + "/packaging/healthcheck", token).then((res: any) => {
            response = res
        }).catch((err: any) => {
            handleError(err)
        })

        return response
    }

    // Filters

    // Modules

    if (loading && (!init.brands || !init.ranges || !init.products)) return <Loading type={"packaging-data"} />

    return (
        <DataContext.Provider
            value={{
                createAny,
                editAny,
                deleteAny,
                // Brands
                brands, brandsLogos, rangesInBrands,
                getBrands,
                // Ranges
                ranges, rangesLogos, getRanges,
                // Products
                products, totalProducts,
                getProducts, getSingleProduct, getSingleProductUrls,
                archiveProducts, restoreProducts,
                // Apps
                apps,
                getApps, getSingleApp,
                // Images
                getLogo,
                handleDropImage,
                // QR CODES
                getSingleQRCode, getSingleQRCodePreview,
                getWIPQRCodePreview,
                handleDropQRCodeImage,
                getDomains, domains,
                getTemplates, qrcodeTemplates,
                downloadSingleQRCode, quickDownloadSingleQRCode,
                // GS1
                verifyGTIN,
                createQRCode, getGS1QRCode, getGS1QRCodePreview,
                // Misc
                downloadSpreadsheet,
                firstVisit, setFirstVisit,
                getHealthchecks,
                // Filters
                commonFilters, setCommonFilters, clearCommonFilters
            }}
        >
            {children}
        </DataContext.Provider>
    )
}