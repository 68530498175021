import React, {useEffect} from "react"
// MUI
import {Box, Divider, Grid, Button, Typography, Alert} from "@mui/material";
import {Add} from "@mui/icons-material";
// Data
import {useUser} from "@/context/UserProvider";
import {useData} from "@/context/PackagingDataProvider";
// Components
import {AppsDataTable} from "@/components/Apps/index";
// Translation
import {useTranslation} from "react-i18next";
import {useModule} from "@/context/ModuleProvider";
import {usePermissions} from "@/context/PermissionsProvider";

export default function AppsTab() {

    const {t} = useTranslation(["apps", "common"])
    const {organisation} = useUser()
    const {apps, getApps, createAny} = useData()
    const {modules} = useModule()
    const {isAccountRole} = usePermissions()

    useEffect(() => {
        if (!!organisation && !apps) getApps()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisation])

    return (
        <>
            <Box sx={{ p: 2 }}>
                <Grid container justifyContent={{ xs: "center" }} rowSpacing={3} columnSpacing={1}>
                    <Grid item xs={12}>
                        <Typography variant={"h4"} component={"p"}>
                            {t("apps_list")}
                        </Typography>
                    </Grid>
                    <Grid item xs={"auto"} md={12}>
                        <Button
                            variant={"contained"}
                            disableElevation
                            startIcon={<Add />}
                            onClick={() => createAny({}, "app")}
                            disabled={isAccountRole(["analyst"])}
                        >
                            {t("app_create")}
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    {(!!modules && (modules.gs1 && modules.geoloc)) &&
                        <Grid item xs={12}>
                            <Alert severity={"info"}>{t("apps_unavailable_for_gs1")}</Alert>
                        </Grid>
                    }

                    <Grid item xs={12}>
                        <AppsDataTable disableActions={isAccountRole(["analyst"])} {...{apps, getApps, t}} />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}